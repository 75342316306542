.form-div {
    width: auto;
}

.mail-icon {
    width: 4rem;
    height: auto;
}

.font-icon {
    font-size: 2rem;
    width: 4rem;
    height: auto;
}

.envelope-icon {
    margin-bottom: 1rem;
    font-size: 2rem;
    width: 4rem;
    height: auto;
}