.footer-div {
    background-color: rgb(78, 16, 211 ); 
}

.media-icon {
    font-size: 2rem;
}

a {
    color: white;
    text-decoration: none;
}

.text-complement {
    color: #F791AF;
}

.font-logo {
    font-family: 'Fredoka Medium';
}