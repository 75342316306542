.slides {
    width: 100%;
    height: 100%;
}

.slide-container {
    width: 160vh;
    height: 120vh;
}

.slides-3 {
    width: 75%;
    height: 75%;
}