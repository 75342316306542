.form-div {
    width: auto;
}

.button-sub {
    background-color: rgb(78, 16, 211 );
    margin-top: 1rem;
    padding: .75rem 1.5rem;
    text-align: center;
    background-size: 200% auto;
    color: white;
    border-radius: 1.5rem;
    display: block;
    
}

.button-sub:hover {
    background-color: #FF91A4;   
    color: white;
}

.invest-note {
    font-size: .8rem;
}