* {
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body {
  background: #fff;
  color: #0B1215;
  font-family: 'RCL Regular', sans-serif;
}

a {
  text-decoration: none;
  line-height: 1rem;
}
h1 {
  font-size: 2.8rem;
}

p {
  font-family: 'RC Regular', sans-serif;
}

/**Fonts**/
@font-face {
  font-family: 'RC Regular';
  src: url('/src/assets/fonts/RadioCanadaBig-Regular.ttf');
}

@font-face {
  font-family: 'RC Medium';
  src: url('/src/assets/fonts/RadioCanadaBig-Medium.ttf');
}

@font-face {
  font-family: 'RC Bold';
  src: url('/src/assets/fonts/RadioCanadaBig-Bold.ttf');
}

@font-face {
  font-family: 'RC Semibold';
  src: url('/src/assets/fonts/RadioCanadaBig-SemiBold.ttf');
}

@font-face {
  font-family: 'RCL Regular';
  src: url('/src/assets/fonts/RadioCanada_Condensed-Regular.ttf');
}

@font-face {
  font-family: 'RCL Light';
  src: url('/src/assets/fonts/RadioCanada_Condensed-Light.ttf');
}

@font-face {
  font-family: 'RCL Medium';
  src: url('/src/assets/fonts/RadioCanada_Condensed-Medium.ttf');
}

@font-face {
  font-family: 'RCL Bold';
  src: url('/src/assets/fonts/RadioCanada_Condensed-Bold.ttf');
}

@font-face {
  font-family: 'RCL SemiBold';
  src: url('/src/assets/fonts/RadioCanada_Condensed-SemiBold.ttf');
}

@font-face {
  font-family: 'Fredoka Medium';
  src: url('/src/assets/fonts/Fredoka-Medium.ttf');
}



