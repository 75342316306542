nav {
    font-family: 'RC Regular', monospace;
    width: 100%;
    background-color: white;
    color: #000;
  
}

.logo {
    width: 8rem;
    height: auto;
}

li {
    font-family: "RC Semibold", sans-serif;
    list-style-type: none;
}

.btn-cta {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%);
    margin: 1rem;
    padding: 0.5rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: white;
    border-radius: 1.5rem;
    display: block;
    -moz-transition: padding-right .2s ease-in-out;
    -webkit-transition: padding-right .2s ease-in-out;
    -ms-transition: padding-right .2s ease-in-out;
    -o-transition: padding-right .2s ease-in-out;
    transition: padding-right .2s ease-in-out;
    
  }

  .btn-cta:hover {
    -moz-transition: padding .5s ease-in-out;
    -webkit-transition: padding .5s ease-in-out;
    -ms-transition: padding .5s ease-in-out;
    -o-transition: padding .5s ease-in-out;
    transition: padding .5s ease-in-out;
    padding-right: 2rem;
    
  background-image: linear-gradient(to right, #FF91A4 0%, #FF91A4  51%, #FF8008  100%);
    color: #fff;
    text-decoration: none;
    
  }

  .arrow-right {
    font-size: 0.8rem;
  }
  

 .nav-link a {
  color: #0B1215;
  text-decoration: none;
  position: relative;
 }

 .nav-link:hover a {
  color: #9260FC;
 }

 .nav-link a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #9260FC;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}
.nav-link a:hover::after {
  
  transform: scaleX(1);
  transform-origin: bottom center;
}


