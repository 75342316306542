.hero {
    width: 100%;
    margin: 0;
    height:auto;
    object-fit: fill;
    opacity: 0.3;
    
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'RC Regular';
}
.header {
  top: 0;
  position: absolute;
  width: 100%;
  z-index: -999;
  background-image: linear-gradient(to right, #F5A400 0%, #C779D0  21%, #000  100%);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
         
         
.space-up {
  margin-top: 10rem;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.btn-cta-hero {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%);
  margin: 1rem;
  padding: 0.5rem 2rem;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 1.5rem;
  display: block;
}

.btn-cta-hero:hover {
  color: #9260FC;
  border-color: #9260FC;
  border-size: 1px;
  background: white;
  -moz-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  background-position: right center; /* change the direction of the change here */
  text-decoration: none;
}

.hero-banner {
    width: fit-content;
}


.tagline {
  font-family: 'RC Regular';
  color: #000;
}


         
